import VueFeedback from '@ui-common/vue-feedback';


export default {
  __name: 'failure-feedback',
  emits: ['call-request'],
  setup(__props) {



return { __sfc: true,VueFeedback }
}

}