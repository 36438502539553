import VueFeedback from '@ui-common/vue-feedback';
import { useStore } from '../composables/store';


export default {
  __name: 'success-feedback',
  emits: ['call-request'],
  setup(__props) {



const { getLoanAmount } = useStore();
const loanAmount = getLoanAmount();

return { __sfc: true,getLoanAmount, loanAmount, VueFeedback, useStore }
}

}