import { computed, getCurrentInstance, nextTick, ref } from 'vue';
import Question from './components/question.vue';
import SuccessFeedback from './components/success-feedback.vue';
import FailureFeedback from './components/failure-feedback.vue';
import { APP_STATES, QUESTIONS } from './constants/constants';
import { useStore } from './composables/store.js';


export default {
  __name: 'csok-calculator',
  setup(__props) {

const appState = ref(APP_STATES.QUESTION);
const currentStep = ref(0);
const currentQuestion = computed(() => QUESTIONS[currentStep.value]);

const vm = getCurrentInstance().proxy;
const { success, vhkModel, model, getLoanAmount } = useStore();

const resetApp = () => {
	success.value = true;
	currentStep.value = 0;
	Object.keys(model).forEach((key) => delete model[key]);
	Object.keys(vhkModel).forEach((key) => delete vhkModel[key]);
	appState.value = APP_STATES.QUESTION;
};

vm.$modalEventBus.$on('modal-closed', (modalId) => {
	if (modalId == 'csok-calculator-modal') {
		resetApp();
	}
});

const setFeedback = () => {
	appState.value = success.value ? APP_STATES.SUCCESS : APP_STATES.FAILURE;
};

const nextStep = (step) => {
	if (step) {
		step == 'feedback' ? setFeedback() : (currentStep.value = step);
	} else if (currentStep.value + 1 < QUESTIONS.length) {
		currentStep.value++;
	} else {
		setFeedback();
	}
};
const setCallRequest = () => {
	appState.value = APP_STATES.VHK;
	nextTick(() => {
		const detail = Object.entries({ ...vhkModel, hitelösszeg: getLoanAmount() + 'M' })
			.map(([key, value]) => `${key}: ${value}`)
			.join(', ');
		const event = new CustomEvent('call-request-res-set-payload', { detail });
		window.dispatchEvent(event);
	});
};

return { __sfc: true,appState, currentStep, currentQuestion, vm, success, vhkModel, model, getLoanAmount, resetApp, setFeedback, nextStep, setCallRequest, computed, getCurrentInstance, nextTick, ref, Question, SuccessFeedback, FailureFeedback, APP_STATES, QUESTIONS, useStore }
}

}