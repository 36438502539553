import VueButtonGroup from '@ui-common/vue-button-group';
import { useStore } from '../composables/store.js';


export default {
  __name: 'question',
  props: {
	question: {
		type: Object,
		required: true
	}
},
  emits: ['next-step'],
  setup(__props, { emit }) {

const props = __props




const { success, model, vhkModel } = useStore();

const setModels = (payload) => {
	model[props.question.model] = payload;
	const answer = props.question.answers.find((answer) => answer.key == payload);
	if (props.question.vhkModel) {
		vhkModel[props.question.vhkModel] = answer.vhkValue;
	}
	if (answer.failure) {
		success.value = false;
	}
	emit('next-step', answer.nextStep);
};

return { __sfc: true,emit, props, success, model, vhkModel, setModels, VueButtonGroup, useStore }
}

}